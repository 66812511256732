<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Procesos de Adquisición</h1>
      </div>
    </div>

    <div class="row g-3" v-for="procesoAdquisicion of Object.keys(procesosAdquisicion)">
      <div class="col-12">
        <h2 class="subtitulo">{{ procesoAdquisicion }}</h2>
      </div>
      <div class="col-12" v-for="file of currentCalls(procesosAdquisicion[procesoAdquisicion])">
        <div class="card shadow h-100" style="  border: 3px solid var(--color-base-light-2);">
          <div class="card-body">
            <h1 class="card-title mb-3 text-start">{{ file.title }}</h1>

            <h6 class="card-subtitle mb-2 fw-bold pill-text c_2 bg_rosa">{{ file.type }}</h6>

            <div class="card-text d-flex justify-content-end">
              <div v-for="link of file.links">
                <a :href="link.link" target="_blank" class="btn btn-principal m-1"
                  v-if="link.link.includes('https://') || link.link.includes('http://')">{{ link.description }}</a>
                <router-link :to="link.link" class="btn btn-principal m-1" v-else>{{ link.description }}</router-link>
              </div>
            </div>
          </div>
          <div v-if="'publicationDate' in file" class="card-footer border-0 bg_blanco">
            <p class="float-end fw-bold pill-text c_4 bg_verde mt-auto">Fecha de publicación: {{ file.publicationDate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Instituto de Educación de Aguascalientes',
          area: 'Instituto de Educación de Aguascalientes',
          telefono: '449 910 5600',
          fecha: '10/10/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "ProcesosAdquisicionView",
  components: {
    RIComponent
  },
  data() {
    return {
      procesosAdquisicion: {
        "Licitaciones Públicas Nacionales/Presenciales": [
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-020-2024 “Adquisición para el Equipamiento Informático y Audiovisual”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-020-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-020-2024-Resumen.pdf"
              }
            ],
            publicationDate: "10/10/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-019-2024 “Servicio de Consultoría Administrativa, procesos, técnicos y tecnologías de la información para poder integrar la información de los Convenios Federales así como optimizar la información integrada en el Sistema Automatizado de Administración y Contabilidad Gubernamental SAACG”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-019-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-019-2024-Resumen.pdf"
              }
            ],
            publicationDate: "09/10/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-018-2024 “Contratación del Suministro e Instalación de Malla Sombra, para el Mantenimiento en Planteles de Educación Básica del Instituto de Educación de Aguascalientes”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-018-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-018-2024-Resumen.pdf"
              }
            ],
            publicationDate: "02/09/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-017-2024 “Programa para el Fortalecimiento de los Servicios Educativos del Instituto de Educación de Aguascalientes”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-017-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-017-2024-Resumen.pdf"
              }
            ],
            publicationDate: "27/07/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-016-2024 “Adquisición de Mobiliario”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-016-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-016-2024-Resumen.pdf"
              }
            ],
            publicationDate: "05/06/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-015-2024 “Adquisición de Artículos de Limpieza para el Stock de Almacén de Recursos Materiales y Diversas Áreas Dependientes del IEA”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-015-2024.pdf"
              }
            ],
            publicationDate: "11/05/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-014-2024 “Adquisición de Paquetes de Materiales Didácticos Necesarios para Fortalecer las Competencias Básicas de Aprendizaje de Lectura y Escritura de Estudios Focalizados en Rezago Profundo, para los Alumnos de Educación Básica del IEA”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-014-2024.pdf"
              }
            ],
            publicationDate: "06/05/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-013-2024 “Adquisición de Artículos de Limpieza para el Stock de Almacén de Recursos Materiales y Diversas Áreas Dependientes del IEA”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-013-2024.pdf"
              }
            ],
            publicationDate: "25/04/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-012-2024 “Adquisición de Vehículos en el Marco de los Festejos del Día del Maestro 2024”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-012-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-012-2024-Resumen.pdf"
              }
            ],
            publicationDate: "03/04/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-011-2024 “Contrato Abierto de Abastecimiento Reiterado Adquisicion Maquinaria, Material de Pintura y Otros de Construcción para el Stock de Almacén de Recursos Materiales, así como Bachilleratos Militarizados del IEA”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-011-2024.pdf"
              }
            ],
            publicationDate: "25/03/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-010-2024 “Servicio de Internet para los Planteles de Educación Básica y Media Superior”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-010-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-010-2024-Resumen.pdf"
              }
            ],
            publicationDate: "20/03/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-009-2024 “Adquisición de Uniformes Escolares para el Ciclo Escolar 2024-2025”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-009-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-009-2024-Resumen.pdf"
              }
            ],
            publicationDate: "07/03/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-008-2024 “Contratación para el Abastecimiento reiterado de Alimentos para el Centro de Atención Infantil SEP, Bachillerato General Militarizado 1er Escuadron de Lanceros de Aguascalientes, Bachillerato General Militarizado José María Chávez y Albergue Anselmo Chávez Quiroz adscrito a la Escuela Secundaría Técnica número 6”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-008-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-008-2024-Resumen.pdf"
              }
            ],
            publicationDate: "26/02/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-007-2024 “Contratacion del Servicio Profesional de Limpieza para el Instituto de Educacion de Aguascalientes”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-007-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-007-2024-Resumen.pdf"
              }
            ],
            publicationDate: "22/02/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-006-2024 “Servicios Administrados de Impresión, Fotocopiado y Escaneo, para los Usuarios de las Distintas Áreas del IEA”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-006-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-006-2024-Resumen.pdf"
              }
            ],
            publicationDate: "15/02/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-005-2024 “Contratación del Servicio de Vigilancia”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-005-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-005-2024-Resumen.pdf"
              }
            ],
            publicationDate: "09/02/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-004-2024 “Servicio de Internet para los Planteles de Educación Básica y Media Superior”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-004-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-004-2024-Resumen.pdf"
              }
            ],
            publicationDate: "09/02/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-003-2024 “Contratación del Servicio para la Evaluación del Examen de Ingreso a Educación Media Superior, Educación Superior (Alumnos Regulares, Rezagados y Foráneos y Rezagados y Rezagados Extraordinario) y a Posgrado 2024 en Modalidad Impresa y/o en Línea desde Casa”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-003-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-003-2024-Resumen.pdf"
              }
            ],
            publicationDate: "01/02/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-002-2024 “Para la Contratación Consolidada del Servicio de Suministro de Gasolina y Vales de Combustible para el Parque Vehicular del Instituto de Educación de  Aguascalientes”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-002-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-002-2024-Resumen.pdf"
              }
            ],
            publicationDate: "29/01/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-001-2024 “Contratación para el Abastecimiento reiterado de Alimentos para el Centro de Atención Infantil SEP, Bachillerato General Militarizado 1er Escuadron de Lanceros de Aguascalientes, Bachillerato General Militarizado José María Chávez, Escuela Normal Rural Justo Sierra Méndez y Albergue Anselmo Chávez Quiroz adscrito a la Escuela Secundaría Técnica número 6”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-001-2024.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/2024/LPN-PRESENCIAL-001-2024-Resumen.pdf"
              }
            ],
            publicationDate: "29/01/2024"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-016-2023. “Programa para el Fortalecimiento de los Servicios Educativos del Instituto de Educación de Aguascalientes”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-016-2023.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-016-2023-Resumen.pdf"
              }
            ],
            publicationDate: "24/11/2023"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-015-2023. “Adquisición para Equipamiento Informático y Audiovisual”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-015-2023.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-015-2023-Resumen.pdf"
              }
            ],
            publicationDate: "10/11/2023"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-014-2023. “Adquisición de Mobiliario”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-014-2023.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-014-2023-Resumen.pdf"
              }
            ],
            publicationDate: "19/08/2023"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-013-2023. Contratación del Servicio de Conectividad a Internet para Planteles Educativos Pertenecientes al Instituto de Educación de Aguascalientes",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-013-2023.pdf"
              },
              {
                description: "Ver Resumen",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-013-2023-Resumen.pdf"
              }
            ],
            publicationDate: "19/06/2023"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-012-2023. “Adquisición de Uniformes Escolares 2023”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-012-2023.pdf"
              }
            ],
            publicationDate: "22/05/2023"
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-011-2023. “Adquisición de Vehículos en el Marco de los Festejos del Día del Maestro 2023”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-011-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-010-2023. “Adquisición de Material Eléctrico para Stock del Almacén de Recursos Materiales y Servicios”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-010-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-009-2023. “Contrato Abierto de Abastecimiento Reiterado de Pintura, Impermeabilizante y otros Insumos”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-009-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-008-2023. “Adquisición de Artículos de Limpieza”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-008-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-007-2023. “Contratación del Servicio de Conectividad a Internet para Planteles Educativos Pertenecientes al Instituto de Educación de Aguascalientes”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-007-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-006-2023. “Contratacion del Servicio Profesional de Limpieza para el Instituto de Educacion de Aguascalientes”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-006-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-005-2023. “Contratación de Servicio de Vigilancia”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-005-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-004-2023. “Para la Contratación Consolidada del Servicio de Suministro de Gasolina y Vales de Combustible para el Parque Vehicular del Instituto de Educación de Aguascalientes”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-004-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-003-2023. “Contratación para el Abastecimiento reiterado de Alimentos para la Escuela Normal Rural Justo Sierra Méndez”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-003-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-002-2023. “Contratación para el Abastecimiento Reiterado de Alimentos para el Centro de Atención Infantil SEP, Bachillerato General Militarizado “1er Escuadron de Lanceros de Aguascalientes, Bachillerato General Militarizado “José María Chávez”, Escuela Normal Rural Justo Sierra Méndez y Albergue Anselmo Chávez Quiroz”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-002-2023.pdf"
              }
            ]
          },
          {
            title: "Licitación Pública Nacional/Presencial LPN-PRESENCIAL-001-2023. “Servicios Administrados de Impresión, Fotocopiado y Escaneo, para los Usuarios de las Distintas Areas del IEA”",
            type: "Licitación Pública Nacional/Presencial",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/LPN-PRESENCIAL-001-2023.pdf"
              }
            ]
          }
        ],
        "Invitaciones a Cuando Menos Tres Personas Electrónicas/Nacionales": [
          {
            title: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional. No. IA-60-N68-901024986-N-2-2023 Implementación de Diagnostico de Evaluación para el Programa Nacional de Inglés”",
            type: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/IA-60-N68-901024986-N-2-2023.pdf"
              }
            ],
            publicationDate: "07/09/2023"
          },
          {
            title: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional. No. IA-901024986-E32-2022 “Adquisición de Material de Oficina”",
            type: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/IA-901024986-E32-2022.pdf"
              }
            ]
          },
          {
            title: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional. No. IA-901024986-E33-2022 “Adquisicion de Equipo de Computo para los Planteles de Telebachilleratos Comunitarios del Estado de Aguascalientes”",
            type: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/IA-901024986-E33-2022.pdf"
              }
            ]
          },
          {
            title: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional. No. IA-901024986-E40-2022 “Adquisición de Material de Limpieza para Planteles de Telebachilleratos Comunitarios”",
            type: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/IA-901024986-E40-2022.pdf"
              }
            ]
          },
          {
            title: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional. No. IA-901024986-E23-2022. “Adquisición de 690 Paquetes de Materiales Educativos Complementarios-1”",
            type: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/IA-901024986-E23-2022.pdf"
              }
            ]
          },
          {
            title: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional. No. IA-901024986-E11-2022. “Capacitación para el Fortalecimiento de Agentes Educativos”",
            type: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/IA-901024986-E11-2022.pdf"
              }
            ]
          },
          {
            title: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional. No. IA-901024986-E12-2022 “Adquisicion de Equipo Adaptado, Tecnológico y/o Multimedia para Educandos con Discapacidad y Aptitudes Sobresalientes”",
            type: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/IA-901024986-E12-2022.pdf"
              }
            ]
          },
          {
            title: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional. No. IA-901024986-E18-2022 “Capacitación para el Fortalecimiento de Agentes Educativos-1”",
            type: "Invitación a Cuando Menos Tres Personas Electrónica/Nacional",
            links: [
              {
                description: "Ver Licitacion",
                link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/procesos-adquisicion/IA-901024986-E18-2022.pdf"
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    currentCalls(calls) {
      return calls.filter((call) => {
        if ('publicationDate' in call) {
          if (this.isValidity(call.publicationDate)) {
            return true;
          }
          return false;
        }
        return true;
      });
    },
    isValidity(publicationDate) {
      if (publicationDate == "") {
        return true;
      }

      let today = new Date();

      let startDateParts = publicationDate.split("/");
      let startDay = parseInt(startDateParts[0], 10);
      let startMonth = parseInt(startDateParts[1], 10) - 1;
      let startYear = parseInt(startDateParts[2], 10);

      let startDate = new Date(startYear, startMonth, startDay);

      if (today >= startDate) {
        return true;
      }

      return false;
    }
  }
}
</script>

<style></style>