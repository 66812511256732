<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">
          IEA (DES) - Sistema Estatal para el Fortalecimiento de la Educación
          Media Superior y Superior (SEFEMSyS)
        </h1>
        <p>
          Establecer el Sistema Estatal para el Fortalecimiento de la Educación
          Media Superior y Superior, integrado por Autoridades Educativas,
          Subsistemas de Educación Media Superior federales y estatales, e
          Instituciones de Educación Media Superior y Superior públicas,
          privadas y autónomas, la Plataforma Virtual de Indicadores, así como
          los procesos, instrumentos y, en general, de todos aquellos elementos
          que contribuyen al objeto de fortalecer la calidad educativa mediante
          la planeación, programación, organización y supervisión de los
          servicios educativos de tipos Media Superior y Superior; con
          fundamento en lo establecido en la Ley de Educación del Estado de
          Aguascalientes.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Comisiones Estatales del SEFEMSyS</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Comisión</th>
              <th>Descripción</th>
              <th>Objetivos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Comisión Estatal de Planeación de la Educación Media Superior
                (CEPEMS)
              </td>
              <td>
                Instancia para la planeación, desarrollo y evaluación de los
                servicios que ofrezcan las Instituciones de Educación Media
                Superior que operen en el Estado de Aguascalientes.
              </td>
              <td>
                <ul>
                  <li>
                    Coadyuvar en la planeación, desarrollo y evaluación de los
                    servicios que ofrecen las IEMS.
                  </li>
                  <li>
                    Coordinar los procesos de otorgamiento, seguimiento,
                    actualización, refrendo o revocación del RVOE.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Comisión Estatal de Planeación de la Educación Superior (CEPES)
              </td>
              <td>
                Instancia para la planeación, desarrollo y evaluación de los
                servicios que ofrezcan las Instituciones de Educación Superior
                que operen en el Estado de Aguascalientes.
              </td>
              <td>
                <ul>
                  <li>
                    Coadyuvar en la planeación, desarrollo y evaluación de los
                    servicios que ofrecen las IES.
                  </li>
                  <li>
                    Coordinar los procesos de otorgamiento, seguimiento,
                    actualización refrendo o revocación del RVOE.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Comisión Estatal para la Mejora de la Educación Superior (CEMES)
              </td>
              <td>
                Instancia para asegurar la calidad de los servicios de apoyo y
                la pertinencia de la oferta educativa de las instituciones de
                educación superior públicas y privadas del Estado, mediante
                ejercicios de autoevaluación colegiada incidir en la mejora
                continua y acreditación de los programas educativos ante
                organismos reconocidos por el Consejo para la Acreditación de la
                Educación Superior (COPAES) y los Comités Interinstitucionales
                para la Evaluación de la Educación Superior (CIEES).
              </td>
              <td>
                <ul>
                  <li>
                    Coadyuvar en la calidad de los servicios de apoyo y
                    programas educativos que ofrecen las IES, mediante programas
                    de mejora continua transversales.
                  </li>
                  <li>
                    Fomentar la certificación de los servicios educativos y la
                    acreditación de los PE.
                  </li>
                  <li>
                    Promover acciones para mejorar la retención de los
                    edtudiantes, mediante estudios de trayecto y análisis de los
                    indices de rendimiento escolar.
                  </li>
                  <li>
                    Impulsar acciones para fortalecer el perfil de la planta
                    docente.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Comisión Estatal para la Vinculación y Pertinencia de la
                Educación Superior (CEVyPES)
              </td>
              <td>
                Coadyuvar con el Instituto de Educación en la deliberación,
                Consulta y concertación con las comunidades Académicas de las
                Instituciones de Educación Superior y Organizaciones Sociales,
                así como los Sectores, Productivos, Público, Social y Privado
                para lograr la Vinculación exitosa y significativa entre los
                mismos.
              </td>
              <td>
                <ul>
                  <li>Fomentar modalidad de formación DUAL y Alternancia.</li>
                  <li>Promover la creatividad, innovación y emprendimiento.</li>
                  <li>
                    Fortalecer el servicio social. Promover la movilidad
                    nacional e internacional.
                  </li>
                  <li>
                    Realizar estudios de: seguimiento de egresados, opinión de
                    empleadores, AST, DACUM, oferta educativa y demanda laboral.
                  </li>
                  <li>
                    Oferta de servicios: educación continua, servicios
                    tecnológicos.
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Stefany Karina Jiménez Colombo',
          area: 'Departamento de Divulgación y Educación Científica y Tecnológica',
          telefono: '449 149 2400 ext. 7805',
          fecha: '18/09/2024',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "SomosView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
