<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Título V LGCG</h1>
      </div>
    </div>

    <div class="row" v-for="directory of tituloVLGCG">
      <div class="col-12">
        <h2 class="subtitulo">Título V LGCG {{ directory.name }}</h2>
      </div>
      <div class="col-12" v-for="subdirectory of directory.contents">
        <table class="table">
          <thead>
            <tr>
              <th colspan="2" class="text-center">Trimestre {{ subdirectory.name.substring(1, 2) }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="fileClassification of Object.keys(classifiedFiles(subdirectory))">
              <td>{{ fileClassification }}</td>
              <td>
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr v-for="file of classifiedFiles(subdirectory)[fileClassification]">
                      <td>
                        {{ file.name }}
                      </td>
                      <td class="text-center" width="50">
                        <a :href="file.path" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Veronica Fernanda Díaz Vidales',
          area: 'Departamento de Planeación y Supervisión',
          telefono: '449 910 5600 ext. 7840',
          fecha: '11/10/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';
import tituloVLGCGData from '@/assets/json/tituloVLGCGData.json';

export default {
  name: "TituloVLGCGView",
  components: {
    RIComponent,
  },
  data() {
    return {
      tituloVLGCG: tituloVLGCGData.reverse(),
    }
  },
  methods: {
    classifiedFiles(directory) {
      const classifiedFiles = directory.contents.reduce((classified, file) => {
        const classification = this.getFileClassification(file);
        classified[classification].push(file);
        return classified;
      }, {
        CONTABLE: [],
        PRESUPUESTARIA: [],
        PROGRAMATICA: [],
        ADICIONAL: [],
      });

      return classifiedFiles;
    },
    getFileClassification(file) {
      if (file.name.includes("D2")) {
        return "CONTABLE";
      } else if (file.name.includes("D3")) {
        return "PRESUPUESTARIA";
      } else if (file.name.includes("D4")) {
        return "PROGRAMATICA";
      } else {
        return "ADICIONAL";
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
