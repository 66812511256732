<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="d-flex align-items-center">
          <img src="https://eservicios2.aguascalientes.gob.mx/portalGea/iconos/i_libro.svg" alt="...">
          <h1 class="titulo">Biblioteca digital del Instituto de Educación de Aguascalientes</h1>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Publicaciones</h1>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-md-4">
      <div class="col text-center" v-for="publication of publications">
        <a :href="publication.path" target="_blank">
          <img :src="publication.image" alt="..." class="img-fluid rounded shadow">
          <p class="fw-bold c_base">{{ publication.name }}</p>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h1 class="titulo">Información estadística y planeación</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Las Cifras de la Educación</h2>
      </div>
    </div>
  
    <div class="row row-cols-1 row-cols-md-4">
      <div class="col text-center" v-for="book of books">
        <a :href="book.path" target="_blank">
          <img :src="book.image" alt="..." class="img-fluid rounded shadow">
          <p class="fw-bold c_base">{{book.name}}</p>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Infografías</h2>
      </div>
    </div>
  
    <div class="row row-cols-1 row-cols-md-4">
      <div class="col text-center" v-for="infographic of infographics">
        <a :href="infographic.path" target="_blank">
          <img :src="infographic.image" alt="..." class="img-fluid rounded shadow">
          <p class="fw-bold c_base">{{infographic.name}}</p>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Gráficos Estadísticos</h2>
      </div>
    </div>
  
    <div class="row row-cols-1 row-cols-md-4">
      <div class="col text-center" v-for="chart of charts">
        <a :href="chart.path" target="_blank">
          <img :src="chart.image" alt="..." class="img-fluid rounded shadow">
          <p class="fw-bold c_base">{{chart.name}}</p>
        </a>
      </div>
    </div>

    <div class="ros">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Veronica Pizaña Gonzalez y José Luis García García',
            area: 'Subdirección de Información Planeación Estratégica Y Evaluación Institucional',
            telefono: '449 910 5694 ext. 4100; 449 910 5600 ext. 4522',
            fecha: '10/10/2024'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';
import bibliotecaDigitalData from '@/assets/json/bibliotecaDigitalData.json';

export default {
  name: "BibliotecaDigitalView",
  components: {
    RIComponent
  },
  data() {
    return {
      publications: bibliotecaDigitalData.find((directory) => directory.name === "publicaciones").contents,
      books: bibliotecaDigitalData.find((directory) => directory.name === "cifras-educacion").contents,
      infographics: bibliotecaDigitalData.find((directory) => directory.name === "infografias").contents,
      charts: bibliotecaDigitalData.find((directory) => directory.name === "graficos").contents
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
