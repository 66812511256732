<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Convocatorias</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <ConvocatoriasComponent :calls="calls" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Instituto de Educación de Aguascalientes',
          area: 'Instituto de Educación de Aguascalientes',
          telefono: '449 910 5600',
          fecha: '10/10/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ConvocatoriasComponent from '../../components/ConvocatoriasComponent.vue';
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "ConvocatoriasView",
  components: {
    ConvocatoriasComponent,
    RIComponent
  },
  data() {
    return {
      calls: [
        {
          title: "Convocatoria Artes Visuales con Perspectiva de Genero para la Escuela",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Artes-Visuales-con-Perspectiva-de-Genero-para-la-Escuela.pdf",
              validity: "10/10/2024 - 18/10/2024"
            },
          ],
          validity: "10/10/2024 - 18/10/2024"
        },
        {
          title: "Convocatoria Curso de Preparación y Certificación IELTS",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Curso-de-Preparación-y-Certificación-IELTS.pdf",
              validity: "08/10/2024 - 14/10/2024"
            },
          ],
          validity: "08/10/2024 - 14/10/2024"
        },
        {
          title: "Convocatoria 1er Torneo de Gotcha",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-1er-Torneo-de-Gotcha.pdf",
              validity: "07/10/2024 - 17/10/2024"
            },
          ],
          validity: "07/10/2024 - 17/10/2024"
        },
        {
          title: "Convocatoria “Con Amor a México”",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Con-Amor-a-México.pdf",
              validity: "03/09/2024 - 27/09/2024"
            },
          ],
          validity: "03/09/2024 - 27/09/2024"
        },
        {
          title: "Convocatoria Concurso de Dibujo “Guardianes del Agua”",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Concurso-de-Dibujo-Guardianes-del-Agua.pdf",
              validity: "30/08/2024 - 11/11/2024"
            },
          ],
          validity: "30/08/2024 - 11/11/2024"
        },
        {
          title: "Convocatoria Maestría en Innovación para la Cultura Física",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-MICF.pdf",
              validity: "26/08/2024 - 02/12/2024"
            },
          ],
          validity: "26/08/2024 - 02/12/2024"
        },
        {
          title: "Programa de Cooperación Educativa Internacional con la hermana República de Cuba",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Programa-de-Cooperación-Educativa-Internacional-Cuba.pdf",
              validity: "26/08/2024 - 09/09/2024"
            },
          ],
          validity: "26/08/2024 - 09/09/2024"
        },
        // {
        //   title: "Programa de Cooperación Educativa Internacional: “Global Educators Program” del Alamo Colleges District en San Antonio, Texas. Estados Unidos de América",
        //   type: "Convocatoria",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Programa-de-Cooperación-Educativa-Internacional-EU.pdf",
        //       validity: "26/08/2024 - 28/08/2024"
        //     },
        //   ],
        //   validity: "26/08/2024 - 28/08/2024"
        // },
        // {
        //   title: "Convocatoria Base del Proceso de Autorización de Cambio de Centro de Trabajo Telebachillerato, Ciclo Escolar 2024-2025",
        //   type: "Convocatoria",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Procesos-de-Autorización-de-Cambio-de-Centro-de-Trabajo-Telebachillerato.pdf",
        //       validity: "08/08/2024 - 12/08/2024"
        //     },
        //   ],
        //   validity: "08/08/2024 - 12/08/2024"
        // },
        // {
        //   title: "Convocatoria Curso de Verano “Tecnolochicas”",
        //   type: "Convocatoria",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Tecnolochicas.pdf",
        //       validity: "22/07/2024 - 14/08/2024"
        //     },
        //     {
        //       description: "Registrar Solicitud",
        //       link: "https://forms.gle/Wu9YhvxGCxCaUcWp8",
        //       validity: "22/07/2024 - 31/07/2024"
        //     },
        //     {
        //       description: "Ver Sedes Alternas",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Tecnolochicas (Sedes Alternas).pdf",
        //       validity: "22/07/2024 - 31/07/2024"
        //     }
        //   ],
        //   validity: "22/07/2024 - 14/08/2024"
        // },
        // {
        //   title: "Convocatoria Apoyos de Movilidad TSU / Francia",
        //   type: "Convocatoria",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Apoyos-de-Movilidad-TSU-Francia.pdf",
        //       validity: "16/07/2024 - 31/07/2024"
        //     },
        //     {
        //       description: "Registrar Solicitud",
        //       link: "https://forms.gle/mHjTeqnUEiYf5TjT9",
        //       validity: "16/07/2024 - 31/07/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Apoyos-de-Movilidad-TSU-Francia.pdf",
        //       validity: "31/07/2024 - 16/08/2024"
        //     }
        //   ],
        //   validity: "16/07/2024 - 16/08/2024"
        // },
        // {
        //   title: "Convocatoria del Proceso de Selección de los Alumnos de Nuevo Ingreso a las Escuelas Normales, la Unidad 011 de la Universidad Pedagógica Nacional y el Centro de Actualización del Magisterio",
        //   type: "Convocatoria",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Ingreso-a-Escuelas-Normales-UPN-y-CAM.pdf",
        //       validity: "21/05/2024 - 16/08/2024"
        //     },
        //     {
        //       description: "Ver Resultados",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/RES-Ingreso-a-Escuelas-Normales-UPN-y-CAM.pdf",
        //       validity: "09/08/2024 - 16/08/2024"
        //     }
        //   ],
        //   validity: "21/05/2024 - 16/08/2024"
        // },
        // {
        //   title: "Convocatorias de Cambios de Adscripción de Estado a Estado",
        //   type: "Convocatoria",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Cambios-de-Adscripción-de-Estado-a-Estado.pdf",
        //       validity: "20/02/2024 - 16/08/2024"
        //     }
        //   ],
        //   validity: "20/02/2024 - 16/08/2024"
        // },
        // {
        //   title: "Convocatoria de Permutas de Adscripción de Estado a Estado",
        //   type: "Convocatoria",
        //   links: [
        //     {
        //       description: "Ver Convocatoria",
        //       link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Permutas-de-Adscripción-de-Estado-a-Estado.pdf",
        //       validity: "20/02/2024 - 16/08/2024"
        //     }
        //   ],
        //   validity: "20/02/2024 - 16/08/2024"
        // }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>

</style>
